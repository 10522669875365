import { ServiceArea } from "@/lib/dto/leads";
import { leadsPerDayFilters } from "@/lib/dto/management";
import { useGetLeadsPerDayQuery } from "@/lib/services/management";
import { useGetServiceAreasQuery } from "@/lib/services/suppliers";
import { useGetProfileQuery } from "@/lib/services/user";
import {
  classNames,
  OptionsForSelects,
  moneyFormatCurrency,
  nameFromId,
} from "@/lib/utils";
import { salesAndLeasesColors } from "@/pages/management";
import { t, Trans } from "@lingui/macro";
import _ from "lodash";
import moment from "moment";
import { useRouter } from "next/router";
import { useEffect, useMemo, useState } from "react";
import Select from "react-select";
import FilterChips from "../FilterChips";
import DonutChart from "./DonutChart";
import ImprovementBadge from "./ImprovementBadge";
import NoDataChart from "./NoDataChart";
import StackedBars from "./StackedBars";
import StackedLoading from "./StackedLoading";

interface Props {
  months: OptionsForSelects[];
}

export default function KPIsCard({ months }: Props) {
  const [currentMonth, setCurrentMonth] = useState<any>();
  const [currentIndustry, setCurrentIndustry] = useState<any>();
  const [currentZones, setCurrentZones] = useState<any>([]);
  const [empty, setEmpty] = useState();

  const [currentGraph, setCurrentGraph] = useState("business");
  const [requirements, setRequirements] = useState({
    series: [],
    xaxis: {
      xaxisBorder: {
        show: false,
      },
      type: "datetime",
      categories: [],
    },
  });
  const [business, setBusiness] = useState({
    series: [],
    xaxis: {
      show: false,
      xaxisBorder: {
        show: false,
      },
      type: "datetime",
      categories: [],
    },
  });
  const { locale } = useRouter();
  const { data: profile } = useGetProfileQuery();
  const { data: leadsPerDay, isLoading } = useGetLeadsPerDayQuery(
    {
      filters: {
        month: currentMonth?.value,
        industry: currentIndustry?.value,
        service_area_ids: currentZones,
      },
    },
    {
      skip: !currentMonth?.value,
    }
  );
  const { data: serviceAreas } = useGetServiceAreasQuery();

  const industries = [
    { label: t`All`, value: "" },
    { label: t`Industrial`, value: "industrial" },
    { label: t`Construction`, value: "construction" },
  ];

  const goalData = {
    series: [3897787, 23897787],
  };

  const tooltip = {
    theme: "dark",
    x: {
      show: true,
    },
    y: {
      formatter: (value: number, opts: any) => {
        return value;
      },
    },
  };

  const handleZones = (zone: ServiceArea) => {
    const newZones = _.cloneDeep(currentZones);
    const index = newZones.findIndex((item: number) => zone.id === item);

    if (index > -1) {
      newZones.splice(index, 1);
      setCurrentZones(newZones);
    } else {
      newZones.push(zone.id);
      setCurrentZones(newZones);
    }
  };

  const handleRemoveZone = (index: number) => {
    const newZones = _.cloneDeep(currentZones);
    newZones.splice(index, 1);
    setCurrentZones(newZones);
  };

  useEffect(() => {
    if (months && !currentMonth) {
      setCurrentMonth(months[0]);
    }
  }, [months]);

  useEffect(() => {
    setCurrentIndustry(industries[0]);
  }, []);

  useEffect(() => {
    if (leadsPerDay) {
      const requirementsCopy = _.cloneDeep(requirements);
      requirementsCopy.xaxis.categories = leadsPerDay.categories;
      requirementsCopy.series = leadsPerDay.leads_series;
      setRequirements(requirementsCopy);
      const businessCopy = _.cloneDeep(business);
      businessCopy.xaxis.categories = leadsPerDay.categories;
      businessCopy.series = leadsPerDay.business_series;
      setBusiness(businessCopy);
    }
  }, [leadsPerDay]);

  return (
    <div className="rounded-md border mb-12">
      <div className="p-4 bg-light-blue-crayola  space-y-4">
        <div className="grid grid-cols-3 gap-4 dashboard-selects">
          <div className="relative">
            <Select
              placeholder={t`Select`}
              options={months}
              className="bg-light-blue-crayola"
              value={currentMonth}
              onChange={(item) => setCurrentMonth(item)}
            />
            <span className="material-icons-outlined text-dark-jungle absolute top-2 left-2 z-[999]">
              date_range
            </span>
          </div>
          <div className="relative">
            <Select
              placeholder={t`Select`}
              options={industries}
              value={currentIndustry}
              onChange={(item) => setCurrentIndustry(item)}
              className="bg-light-blue-crayola"
            />
            <span className="material-icons-outlined text-dark-jungle absolute top-2 left-2 z-[999]">
              factory
            </span>
          </div>
          <div className="relative">
            <Select
              placeholder={t`Select`}
              options={serviceAreas}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id.toString()}
              onChange={(item) => handleZones(item as ServiceArea)}
              className="bg-light-blue-crayola"
            />
            <span className="material-icons-outlined text-dark-jungle absolute top-2 left-2 z-[999]">
              pin_drop
            </span>
          </div>
        </div>
        <div>
          <ul className="flex gap-2 items-center flex-wrap">
            {currentZones?.map((zone: number, index: number) => (
              <li key={zone}>
                <span className="inline-flex min-w-[50px] bg-white items-center rounded-full border py-0.5 pl-2.5 pr-1 text-sm font-medium">
                  {nameFromId(zone, serviceAreas, "name")}
                  <button
                    onClick={() => handleRemoveZone(index)}
                    type="button"
                    className="ml-0.5 inline-flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-full hover:bg-gray-200 focus:bg-gray-200 focus:text-white focus:outline-none"
                  >
                    <span className="sr-only">Remove filter</span>
                    <svg
                      className="h-2 w-2"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 8 8"
                    >
                      <path
                        strokeLinecap="round"
                        strokeWidth="1.5"
                        d="M1 1l6 6m0-6L1 7"
                      />
                    </svg>
                  </button>
                </span>
              </li>
            ))}
          </ul>
        </div>
        <div className="kpis-chart">
          <div className="flex items-center justify-center">
            <div className="bg-blue-crayola/20 rounded-md p-2 inline-flex">
              <div
                onClick={() => setCurrentGraph("business")}
                className={classNames(
                  currentGraph === "business" ? "bg-white shadow" : "",
                  "cursor-pointer rounded-md p-2 text-xs"
                )}
              >
                {t`Business`}
              </div>
              <div
                onClick={() => setCurrentGraph("requirements")}
                className={classNames(
                  currentGraph === "requirements" ? "bg-white shadow" : "",
                  "cursor-pointer rounded-md p-2 text-xs"
                )}
              >
                {t`Requirements`}
              </div>
            </div>
          </div>
          {isLoading ? (
            <>
              <StackedLoading name="business_principal" />
            </>
          ) : (
            <>
              {leadsPerDay?.invoiced?.total > 0 ? (
                <StackedBars
                  size={200}
                  tooltip={tooltip}
                  data={currentGraph === "business" ? business : requirements}
                  colors={salesAndLeasesColors}
                />
              ) : (
                <NoDataChart />
              )}
            </>
          )}
        </div>

        <div className="px-4 py-2 rounded-md bg-white shadow space-y-4">
          <div className="flex gap-1 2xl:gap-2 items-center">
            <span className="material-icons-outlined text-blue-crayola">
              monetization_on
            </span>
            <h1 className="2xl:text-lg font-semibold">{t`Billing`}</h1>
          </div>
          <div className="grid grid-cols-3 divide-x space-x-1 2xl:space-x-2">
            {isLoading ? (
              <>
                <div className="animate-pulse text-center">
                  <div className="h-6 bg-gray-200 mb-2 w-full 2xl:w-36 mx-auto rounded mt-2"></div>
                  <p className="text-sm 2xl:text-lg font-semibold">
                    <Trans>Total</Trans>
                  </p>
                </div>
                <div className="animate-pulse text-center">
                  <div className="h-4 bg-gray-200 mb-2 w-full 2xl:w-36 mx-auto rounded mt-2"></div>
                  <p className="text-xs font-semibold">
                    <Trans>Sales</Trans>
                  </p>
                </div>
                <div className="animate-pulse text-center">
                  <div className="h-4 bg-gray-200 mb-2 w-full 2xl:w-36 mx-auto rounded mt-2"></div>
                  <p className="text-xs font-semibold">
                    <Trans>Leases</Trans>
                  </p>
                </div>
              </>
            ) : (
              <>
                <div className="text-center">
                  <p className="text-sm 2xl:text-lg font-semibold text-center">
                    {moneyFormatCurrency(leadsPerDay?.invoiced?.total, locale)}
                  </p>
                  <p className="text-sm 2xl:text-lg font-semibold">
                    <Trans>Total</Trans>
                  </p>
                </div>
                <div className="text-center">
                  <p className="text-sm 2xl:text-md font-semibold text-gray-500">
                    {moneyFormatCurrency(leadsPerDay?.invoiced?.sale, locale)}
                  </p>
                  <p className="text-xs inline-flex gap-1 2xl:gap-2 items-center text-lg font-semibold text-blue-crayola">
                    <span className="block h-2 w-2 rounded-full bg-blue-crayola"></span>
                    {leadsPerDay?.invoiced?.sale_percentage}%{" "}
                    <Trans>Sales</Trans>
                  </p>
                </div>
                <div className="text-center">
                  <p className="text-sm 2xl:text-md font-semibold text-gray-500">
                    {moneyFormatCurrency(leadsPerDay?.invoiced?.lease, locale)}
                  </p>
                  <p className="text-xs inline-flex gap-1 2xl:gap-2 items-center font-semibold text-safety-orange">
                    <span className="block h-2 w-2 rounded-full bg-safety-orange"></span>
                    {leadsPerDay?.invoiced?.lease_percentage}%{" "}
                    <Trans>Leases</Trans>
                  </p>
                </div>
              </>
            )}
          </div>
          {isLoading ? (
            <div className="bg-gray-200 w-[150px] rounded-full p-1">
              <div className="animate-pulse flex items-center gap-2">
                <div className="rounded-full bg-gray-300 w-4 h-4"></div>
                <div className="rounded-full bg-gray-300 w-[100px] h-4"></div>
              </div>
            </div>
          ) : (
            <>
              {leadsPerDay?.invoiced?.improvement_percentage !== null && (
                <ImprovementBadge
                  value={leadsPerDay?.invoiced?.improvement_percentage}
                />
              )}
            </>
          )}
        </div>
        <div className="px-4 py-2 rounded-md bg-white shadow space-y-4">
          <div className="flex gap-1 2xl:gap-2 items-center">
            <span className="material-icons-outlined text-tiffany-blue">
              auto_graph
            </span>
            <h1 className="2xl:text-lg font-semibold">{t`Profit`}</h1>
          </div>
          <div className="grid grid-cols-3 divide-x space-x-2">
            {isLoading ? (
              <>
                <div className="animate-pulse text-center">
                  <div className="h-6 bg-gray-200 mb-2  w-full 2xl:w-36 mx-auto rounded mt-2"></div>
                  <p className="text-lg font-semibold">
                    <Trans>Total</Trans>
                  </p>
                </div>
                <div className="animate-pulse text-center">
                  <div className="h-4 bg-gray-200 mb-2  w-full 2xl:w-36 mx-auto rounded mt-2"></div>
                  <p className="text-xs font-semibold">
                    <Trans>Sales</Trans>
                  </p>
                </div>
                <div className="animate-pulse text-center">
                  <div className="h-4 bg-gray-200 mb-2  w-full 2xl:w-36 mx-auto rounded mt-2"></div>
                  <p className="text-xs font-semibold">
                    <Trans>Leases</Trans>
                  </p>
                </div>
              </>
            ) : (
              <>
                <div className="text-center">
                  <p className="text-sm 2xl:text-lg font-semibold">
                    {moneyFormatCurrency(leadsPerDay?.margin?.total, locale)}
                  </p>
                  <p className="text-sm 2xl:text-lg font-semibold">
                    <Trans>Total</Trans>
                  </p>
                </div>
                <div className="text-center">
                  <p className="text-sm 2xl:text-md font-semibold text-gray-500">
                    {moneyFormatCurrency(leadsPerDay?.margin?.sale, locale)}
                  </p>
                  <p className="text-xs inline-flex gap-1 2xl:gap-2 items-center font-semibold text-blue-crayola">
                    <span className="block h-2 w-2 rounded-full bg-blue-crayola"></span>
                    {leadsPerDay?.margin?.sale_percentage}% <Trans>Sales</Trans>
                  </p>
                </div>
                <div className="text-center">
                  <p className="text-sm 2xl:text-md font-semibold text-gray-500">
                    {moneyFormatCurrency(leadsPerDay?.margin?.lease, locale)}
                  </p>
                  <p className="text-xs inline-flex 2xl:gap-2 items-center font-semibold text-safety-orange">
                    <span className="block h-2 w-2 rounded-full bg-safety-orange"></span>
                    {leadsPerDay?.margin?.lease_percentage}%{" "}
                    <Trans>Leases</Trans>
                  </p>
                </div>
              </>
            )}
          </div>
          {isLoading ? (
            <div className="bg-gray-200 w-[150px] rounded-full p-1">
              <div className="animate-pulse flex items-center gap-2">
                <div className="rounded-full bg-gray-300 w-4 h-4"></div>
                <div className="rounded-full bg-gray-300 w-[100px] h-4"></div>
              </div>
            </div>
          ) : (
            <>
              {leadsPerDay?.margin?.improvement_percentage !== null && (
                <ImprovementBadge
                  value={leadsPerDay?.margin?.improvement_percentage}
                />
              )}
            </>
          )}
        </div>
        <div className="px-4 py-2 rounded-md bg-white shadow space-y-4">
          <div className="flex gap-1 2xl:gap-2 items-center">
            <span className="material-icons-outlined text-fiery-rose">
              handshake
            </span>
            <h1 className="2xl:text-lg font-semibold">{t`Closing rate`}</h1>
          </div>
          <div className="grid grid-cols-3 divide-x space-x-2">
            {isLoading ? (
              <>
                <div className="animate-pulse text-center">
                  <div className="h-6 bg-gray-200 mb-2 w-full 2xl:w-36 mx-auto rounded mt-2"></div>
                  <p className="2xl:text-lg font-semibold">
                    <Trans>Total</Trans>
                  </p>
                </div>
                <div className="animate-pulse text-center">
                  <div className="h-4 bg-gray-200 mb-2 w-full 2xl:w-36 mx-auto rounded mt-2"></div>
                  <p className="text-xs font-semibold">
                    <Trans>Sales</Trans>
                  </p>
                </div>
                <div className="animate-pulse text-center">
                  <div className="h-4 bg-gray-200 mb-2 w-full 2xl:w-36 mx-auto rounded mt-2"></div>
                  <p className="text-xs font-semibold">
                    <Trans>Leases</Trans>
                  </p>
                </div>
              </>
            ) : (
              <>
                <div className="text-center">
                  <p className="text-sm 2xl:text-lg font-semibold">
                    {leadsPerDay?.closed?.total_percentage}%
                  </p>
                  <p className="text-sm 2xl:text-lg font-semibold">
                    <Trans>Total</Trans>
                  </p>
                </div>
                <div className="text-center">
                  <p className="text-sm 2xl:text-md font-semibold text-gray-500">
                    {leadsPerDay?.closed?.sale_percentage}%
                  </p>
                  <p className="text-xs inline-flex gap-2 items-center text-lg font-semibold text-blue-crayola">
                    <span className="block h-2 w-2 rounded-full bg-blue-crayola"></span>
                    <Trans>Sales</Trans>
                  </p>
                </div>
                <div className="text-center">
                  <p className="text-sm 2xl:text-md font-semibold text-gray-500">
                    {leadsPerDay?.closed?.lease_percentage}%
                  </p>
                  <p className="text-xs inline-flex gap-2 items-center text-lg font-semibold text-safety-orange">
                    <span className="block h-2 w-2 rounded-full bg-safety-orange"></span>
                    <Trans>Leases</Trans>
                  </p>
                </div>
              </>
            )}
          </div>
          {isLoading ? (
            <div className="bg-gray-200 w-[150px] rounded-full p-1">
              <div className="animate-pulse flex items-center gap-2">
                <div className="rounded-full bg-gray-300 w-4 h-4"></div>
                <div className="rounded-full bg-gray-300 w-[100px] h-4"></div>
              </div>
            </div>
          ) : (
            <>
              {leadsPerDay?.closed?.improvement_percentage !== null && (
                <ImprovementBadge
                  value={leadsPerDay?.closed?.improvement_percentage}
                />
              )}
            </>
          )}
        </div>
      </div>
      <div className="p-4 bg-light-tiffany-blue  space-y-4">
        {isLoading ? (
          <div className="animate-pulse flex items-center gap-4">
            <div className="w-[120px] h-[120px] rounded-full bg-gray-300 shrink-0"></div>
            <div className="space-y-4 w-full">
              <div className="bg-gray-300 h-8 rounded-md w-full"></div>
              <div className="bg-gray-300 h-8 rounded-md w-full"></div>
            </div>
          </div>
        ) : (
          <>
            {leadsPerDay?.goals ? (
              <div className="flex items-center gap-4">
                <div className="goals-donut-chart shrink-0 w-[130px] flex items-center">
                  <DonutChart data={leadsPerDay?.goals} />
                </div>
                <p className="text-xl font-semibold text-tiffany-blue">
                  <small>
                    <Trans>We are</Trans>{" "}
                  </small>
                  {moneyFormatCurrency(
                    Math.abs(leadsPerDay?.goals?.to_goal),
                    locale
                  )}{" "}
                  <small>
                    {leadsPerDay?.goals?.to_goal > 0 ? (
                      <Trans>away from reaching the goal for</Trans>
                    ) : (
                      <Trans>ahead of our goal for</Trans>
                    )}{" "}
                    {moment(leadsPerDay.goals.star_date).format("DD/MM/YYYY")}-{" "}
                    {moment(leadsPerDay.goals.end_date).format("DD/MM/YYYY")}!
                  </small>
                </p>
              </div>
            ) : (
              <>
                <div className="h-36 flex flex-col items-center justify-center space-y-4 text-gray-500">
                  <span className="material-icons-outlined">
                    workspace_premium
                  </span>
                  <p className="text-center text-xs text-gray-500">
                    <Trans>
                      Theres not a goal setted or matching with the current
                      filters setted
                    </Trans>
                  </p>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}
