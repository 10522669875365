import { Tooltip, Yaxis } from "@/lib/dto/management";
import dynamic from "next/dynamic";
import { useEffect, useState } from "react";

const Chart = dynamic(() => import("react-apexcharts"), { ssr: false });

interface Props {
  data: any;
  size?: number;
  colors: string[];
  tooltip?: Tooltip;
  stacked?: boolean;
  yaxis?: Yaxis;
}

export default function StackedBars({
  data,
  size = 150,
  colors,
  tooltip,
  stacked = true,
  yaxis,
}: Props) {
  const [options, setOptions] = useState({
    chart: {
      height: size,
      type: "bar",
      stacked: stacked,
      toolbar: {
        show: true,
      },
    },
    legend: {
      position: "bottom",
      horizontalAlign: "left",
      markers: {
        radius: 6,
        offsetY: 2,
        offsetX: -2,
      },
      labels: {
        useSeriesColors: true,
      },
      fontFamily: "Nunito Sans",
      fontSize: "12px",
    },
    dataLabels: {
      enabled: false,
    },
    colors: colors,
    stroke: {
      curve: "smooth",
    },
    grid: {
      show: false,
    },
    xaxis: data.xaxis,
    yaxis: {
      show: false,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: data?.series[0]?.data.length > 12 ? 6 : 14,
        borderRadiusApplication: "around", // 'around', 'end'
        borderRadiusWhenStacked: "all", // 'all', 'last'
        dataLabels: {
          total: {
            enabled: true,
            style: {
              fontSize: "13px",
              fontWeight: 900,
            },
          },
        },
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
      custom: function ({ series, seriesIndex, dataPointIndex, w }: any) {
        let currentTotal = 0;
        const ul = document.createElement("ul");
        ul.className = "custom-tooltip-apexchart";
        series.forEach((s: any, index: number) => {
          currentTotal += s[dataPointIndex];
          let li = document.createElement("li");
          li.innerHTML = ` <p> <span class="tooltip-color-legend" style="background: ${w.config.colors[index]}"></span> ${w.config.series[index].name}: ${s[dataPointIndex]}</p>`;
          ul.append(li);
        });

        let totalLi = document.createElement("li");
        totalLi.innerHTML = ` <p>Total: ${currentTotal}</p>`;
        ul.append(totalLi);

        return ul.outerHTML;
      },
    },
  });

  useEffect(() => {
    if (data) {
      setOptions({
        ...options,
        plotOptions: {
          ...options.plotOptions,
          bar: {
            ...options.plotOptions.bar,
            borderRadius:
              data[0]?.data.length > 12 || data.series.length > 2 ? 8 : 14,
          },
        },
        xaxis: data.xaxis,
        yaxis: {
          ...options.yaxis,
          ...yaxis,
        },
        tooltip: {
          ...options.tooltip,
          ...tooltip,
        },
      });
    }
  }, [data]);

  return (
    <div className="">
      {data && (
        <div style={{ height: size }}>
          <Chart
            // @ts-ignore
            options={options}
            series={data.series}
            type="bar"
            height={size}
          />
        </div>
      )}
    </div>
  );
}
