import { YearsItems } from "@/lib/dto/management";
import { useGetDashboardClosedSalesQuery } from "@/lib/services/management";
import { marginColor, saleColor } from "@/pages/management";
import { t } from "@lingui/macro";
import { useEffect, useState } from "react";
import ChartCard from "../ChartCard";

interface Props {
  years: YearsItems[];
}

export default function DashboardClosedSales({ years }: Props) {
  const [currentYear, setCurrentYear] = useState<YearsItems>();
  const { data: dashboardClosedSales, isLoading: dashboardLeasesLoading } =
    useGetDashboardClosedSalesQuery(currentYear?.value, {
      skip: !currentYear?.value,
    });

  const tooltip = {
    theme: "dark",
    x: {
      show: false,
    },
    y: {
      formatter: (value: number, opts: any) => {
        return value;
      },
    },
  };

  useEffect(() => {
    if (years && !currentYear) setCurrentYear(years[0]);
  }, [years]);

  return (
    <ChartCard
      name="closed_sales"
      title={t`Closed sales`}
      icon="handshake"
      iconColor="text-blue-crayola"
      colors={[saleColor, marginColor]}
      data={dashboardClosedSales}
      isLoading={dashboardLeasesLoading}
      years={years}
      chartType="mixed"
      currentYear={currentYear}
      setCurrentYear={setCurrentYear}
      tooltip={tooltip}
    />
  );
}
