import { Trans } from "@lingui/macro";

export default function NoDataChart() {
  return (
    <div className="h-36 flex flex-col items-center justify-center space-y-4 text-gray-500">
      <span className="material-icons-outlined">bar_chart</span>
      <p className="text-center text-xs text-gray-500">
        <Trans>
          Theres not enough info to render this graph, you can try changing the
          mix of the filters.
        </Trans>
      </p>
    </div>
  );
}
