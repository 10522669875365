import { classNames } from "@/lib/utils";

interface Props {
  name: string;
}

export default function StackedLoading({ name }: Props) {
  return (
    <div className="flex items-end justify-between animate-pulse p-4">
      {[24, 32, 14, 36, 10, 20, 30, 22, 16, 12, 28, 48].map((item) => (
        <div key={`${name}${item}`} className="">
          <div
            className={classNames(
              `h-${item}`,
              "bg-gray-200 mb-2 w-6 mx-auto rounded-full mt-2"
            )}
          ></div>
        </div>
      ))}
    </div>
  );
}
