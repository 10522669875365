import { YearsItems } from "@/lib/dto/management";
import {
  useGetDashboardInvoicedByBusinessQuery,
  useGetDashboardLeasesQuery,
  useGetDashboardTakeRateQuery,
} from "@/lib/services/management";
import { getMinMax, moneyFormatCurrency } from "@/lib/utils";
import {
  baseColor,
  extensionsColor,
  leaseColor,
  othersColor,
} from "@/pages/management";
import { t } from "@lingui/macro";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import ChartCard from "../ChartCard";

interface Props {
  years: YearsItems[];
}

export default function DashboardTakeRate({ years }: Props) {
  const { locale } = useRouter();
  const [currentYear, setCurrentYear] = useState<YearsItems>();
  const [yaxis, setYaxis] = useState({
    min: 0,
    max: 0,
  });
  const { data: takeRate, isLoading: takeRateLoading } =
    useGetDashboardTakeRateQuery(currentYear?.value, {
      skip: !currentYear?.value,
    });

  const tooltip = {
    theme: "dark",
    x: {
      show: false,
    },
    y: {
      formatter: (value: number, opts: any) => {
        return value;
      },
    },
    custom: function ({ series, seriesIndex, dataPointIndex, w }: any) {
      let currentTotal = 0;
      const ul = document.createElement("ul");
      ul.className = "custom-tooltip-apexchart";
      series.forEach((s: any, index: number) => {
        currentTotal += s[dataPointIndex];
        let li = document.createElement("li");
        li.innerHTML = ` <p> <span class="tooltip-color-legend" style="background: ${
          w.config.colors[index]
        }"></span> ${w.config.series[index].name}: ${moneyFormatCurrency(
          s[dataPointIndex],
          locale
        )}</p>`;
        ul.append(li);
      });

      return ul.outerHTML;
    },
  };

  useEffect(() => {
    if (years && !currentYear) setCurrentYear(years[0]);
  }, [years]);

  useEffect(() => {
    if (takeRate) setYaxis(getMinMax(takeRate));
  }, [takeRate]);

  return (
    <ChartCard
      name="take_rate"
      title={t`Take rate`}
      iconColor="text-blue-crayola"
      icon="monetization_on"
      colors={[baseColor, extensionsColor, othersColor]}
      data={takeRate}
      isLoading={takeRateLoading}
      years={years}
      currentYear={currentYear}
      setCurrentYear={setCurrentYear}
      tooltip={tooltip}
    />
  );
}
