import Layout from "@/components/layout";
import { DashboardPage } from "@/lib/features/auth/DashboardPage";
import { t, Trans } from "@lingui/macro";
import Head from "next/head";
import { useGetProfileQuery } from "@/lib/services/user";
import KPIsCard from "@/components/Management/KPIsCard";
import Select from "react-select";
import StackedBars from "@/components/Management/StackedBars";
import ChartCard from "@/components/Management/ChartCard";
import moment from "moment";
import {
  useGetDashboardFiltersQuery,
  useGetDashboardLeasesQuery,
  useGetDashboardSalesQuery,
  useGetDashboardTotalNetQuery,
} from "@/lib/services/management";
import DashboardLeases from "@/components/Management/Charts/Leases";
import DashboardSales from "@/components/Management/Charts/Sales";
import DashboardTotalNet from "@/components/Management/Charts/TotalNet";
import DashboardSalesNet from "@/components/Management/Charts/SalesNet";
import DashboardLeasesNet from "@/components/Management/Charts/LeasesNet";
import DashboardNetMargin from "@/components/Management/Charts/NetMargin";
import DashboardSalesMargin from "@/components/Management/Charts/SalesMargin";
import { useEffect, useState } from "react";
import {
  OptionsForSelects,
  monthsBetweenDates,
  yearsBetweenDates,
} from "@/lib/utils";
import DashboardManagementSettings from "@/components/Management/Settings";
import _ from "lodash";
import DashboardLeads from "@/components/Management/Charts/Leads";
import DashboardClosedSales from "@/components/Management/Charts/ClosedSales";
import DashboardLeasesMargin from "@/components/Management/Charts/LeasesMargin";
import DashboardClosedLeases from "@/components/Management/Charts/ClosedLeases";
import DashboardTicketSales from "@/components/Management/Charts/TicketSales";
import DashboardTicketLeases from "@/components/Management/Charts/TicketLeases";
import DashboardLeaseDays from "@/components/Management/Charts/LeaseDays";
import DashboardInvoicedByBusiness from "@/components/Management/Charts/InvoicedByBusiness";
import MarginByBusiness from "@/components/Management/Charts/MarginByBusiness";
import DashboardTakeRate from "@/components/Management/Charts/TakeRate";

export const saleColor = "#256EFF";
export const leaseColor = "#FF7500";
export const equipmentColor = "#FF1C5D";
export const marginColor = "#17C3B2";
export const extensionsColor = "#FF1C5D";
export const newLeasesColor = "#256EFF";
export const averageDaysColor = "#FE5171";
export const baseColor = "#256EFF";
export const othersColor = "#FAB900";

interface DashboardSettings {
  chartsToDisplay: string[];
}

export const salesAndLeasesColors = [leaseColor, saleColor, extensionsColor];

const ManagementDashboard: DashboardPage = () => {
  const [settings, setSettings] = useState<DashboardSettings>({
    chartsToDisplay: [],
  });
  const [months, setMonths] = useState<OptionsForSelects[]>([]);
  const [years, setYears] = useState<OptionsForSelects[]>([]);
  const { data: profile } = useGetProfileQuery();
  const { data: filters } = useGetDashboardFiltersQuery();

  const availableCharts = [
    { value: "leases", label: t`Leases` },
    { value: "sales", label: t`Sales` },
    { value: "total_net", label: t`Total ($)` },
    { value: "sales_net", label: t`Sales ($)` },
    { value: "leases_net", label: t`Leases ($)` },
    { value: "margin_net", label: t`Net Margin ($)` },
    { value: "margin_sales_net", label: t`Sales margin ($)` },
    { value: "margin_leases_net", label: t`Leases margin ($)` },
    { value: "requirements", label: t`Requirements` },
    { value: "closed_sales", label: t`Closed sales` },
    { value: "closed_leases", label: t`Closed leases` },
    { value: "ticket_sales", label: t`Ticket sales` },
    { value: "ticket_leases", label: t`Ticket leases` },
    { value: "lease_days", label: t`Lease days` },
    { value: "invoiced_by_business", label: t`Invoiced by business` },
    { value: "margin_by_business", label: t`Margin by business` },
    { value: "take_rate", label: t`Take rate` },
  ];

  const showChart = (chart: string) => {
    const chartsToDisplay = _.cloneDeep(settings.chartsToDisplay);
    return chartsToDisplay.includes(chart);
  };

  useEffect(() => {
    let datestart = moment(filters?.first_date);
    let dateend = moment(filters?.last_date);

    if (!datestart.isValid() || !dateend.isValid()) return;

    setMonths(monthsBetweenDates(datestart, dateend).reverse());
    setYears(yearsBetweenDates(datestart, dateend).reverse());
  }, [filters]);

  useEffect(() => {
    const dashboardChartsToDisplay = localStorage.getItem(
      "dashboardChartsToDisplay"
    );
    if (dashboardChartsToDisplay) {
      setSettings({
        ...settings,
        chartsToDisplay: JSON.parse(dashboardChartsToDisplay),
      });
    } else {
      setSettings({
        ...settings,
        chartsToDisplay: availableCharts.map((chart) => chart.value),
      });
    }
  }, []);

  return (
    <Layout hideTitle={true} title={t`Requirements`} current="Requirements">
      <Head>
        <title>
          {t`Management dashboard`} | {t`rendalo-title`}
        </title>
      </Head>
      <div className="">
        <div className="min-w-0 p-4 flex gap-4 items-center justify-between mt-4">
          <div className="flex items-center gap-2">
            <h1 className="text-xl font-semibold" id="section-title">
              {t`Hello`}
              {profile && (profile?.user.first_name || profile?.user.last_name)
                ? ", "
                : ""}
              {profile?.user.first_name} {profile?.user.last_name}
            </h1>
            <div className="px-2 text-gray-600 rounded-full bg-blue-crayola/10 py-1 text-blue-crayola flex items-center gap-2">
              <span className="material-icons-outlined">info</span>
              <small>{t`All the monetary amounts reflected in this dashboard are net values ​​and in local currency`}</small>{" "}
            </div>
          </div>
          <div className="flex items-center">
            <DashboardManagementSettings
              availableCharts={availableCharts}
              settings={settings}
              setSettings={setSettings}
            />
          </div>
        </div>
        <div className="grid grid-cols-16 gap-4">
          <div className="col-span-6 2xl:col-span-5 pl-4">
            <KPIsCard months={months} />
          </div>
          <div className="col-span-10 2xl:col-span-11 pb-12 pr-4">
            <div className="grid grid-cols-2 2xl:grid-cols-3 gap-4 ">
              {showChart("leases") && (
                <div className="col-span-1">
                  <DashboardLeases years={years} />
                </div>
              )}
              {showChart("sales") && (
                <div className="col-span-1">
                  <DashboardSales years={years} />
                </div>
              )}
              {showChart("total_net") && (
                <div className="col-span-1">
                  <DashboardTotalNet years={years} />
                </div>
              )}
              {showChart("sales_net") && (
                <div className="col-span-1">
                  <DashboardSalesNet years={years} />
                </div>
              )}
              {showChart("leases_net") && (
                <div className="col-span-1">
                  <DashboardLeasesNet years={years} />
                </div>
              )}
              {showChart("margin_net") && (
                <div className="col-span-1">
                  <DashboardNetMargin years={years} />
                </div>
              )}
              {showChart("margin_sales_net") && (
                <div className="col-span-1">
                  <DashboardSalesMargin years={years} />
                </div>
              )}
              {showChart("margin_leases_net") && (
                <div className="col-span-1">
                  <DashboardLeasesMargin years={years} />
                </div>
              )}
              {showChart("requirements") && (
                <div className="col-span-1">
                  <DashboardLeads years={years} />
                </div>
              )}
              {showChart("closed_sales") && (
                <div className="col-span-1">
                  <DashboardClosedSales years={years} />
                </div>
              )}
              {showChart("closed_leases") && (
                <div className="col-span-1">
                  <DashboardClosedLeases years={years} />
                </div>
              )}
              {showChart("ticket_sales") && (
                <div className="col-span-1">
                  <DashboardTicketSales years={years} />
                </div>
              )}
              {showChart("ticket_leases") && (
                <div className="col-span-1">
                  <DashboardTicketLeases years={years} />
                </div>
              )}
              {showChart("lease_days") && (
                <div className="col-span-1">
                  <DashboardLeaseDays years={years} />
                </div>
              )}
              {showChart("invoiced_by_business") && (
                <div className="col-span-1">
                  <DashboardInvoicedByBusiness years={years} />
                </div>
              )}
              {showChart("margin_by_business") && (
                <div className="col-span-1">
                  <MarginByBusiness years={years} />
                </div>
              )}
              {showChart("take_rate") && (
                <div className="col-span-1">
                  <DashboardTakeRate years={years} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

ManagementDashboard.isRendalo = true;
ManagementDashboard.auth = true;
export default ManagementDashboard;
