import { OptionsForSelects } from "@/lib/utils";
import { Trans, t } from "@lingui/macro";
import _ from "lodash";
import { useState } from "react";

interface Props {
  settings: any;
  setSettings: Function;
  availableCharts: OptionsForSelects[];
}

export default function DashboardManagementSettings({
  settings,
  setSettings,
  availableCharts,
}: Props) {
  const [showSettings, setShowSettings] = useState(false);

  const handleOnChangeCharts = async (value: string) => {
    const newCharts = [...settings.chartsToDisplay];
    const index = newCharts.findIndex((x) => x === value);

    if (index === -1) {
      newCharts.push(value);
      setSettings({
        ...settings,
        chartsToDisplay: newCharts,
      });
    } else {
      newCharts.splice(index, 1);
      setSettings({
        ...settings,
        chartsToDisplay: newCharts,
      });
    }

    localStorage.setItem("dashboardChartsToDisplay", JSON.stringify(newCharts));
  };

  const chartChecked = (chart: string) => {
    const chartsToDisplay = _.cloneDeep(settings.chartsToDisplay);
    return chartsToDisplay.includes(chart);
  };

  return (
    <div className="relative">
      <button
        onClick={() => setShowSettings(!showSettings)}
        className="relative hover:bg-dark-jungle/10 rounded-md h-[34px] w-[34px] flex items-center justify-center"
      >
        <span className="material-icons-outlined text-gray-400">settings</span>
      </button>

      {showSettings && (
        <div className="absolute top-full right-0 bg-white rounded-md shadow p-4 z-[99999] w-[200px]">
          <h3 className="text-lg">
            <Trans>Dashboard settings</Trans>
          </h3>

          <div className="mt-4">
            <p className="font-semibold">
              <Trans>Show</Trans>
            </p>
            <ul className="">
              {availableCharts?.map((chart) => (
                <li className="mb-2" key={chart.value}>
                  <div className="relative flex items-start">
                    <div className="flex items-center h-5">
                      <input
                        id={chart.value}
                        aria-describedby="offers-description"
                        name={chart.value}
                        checked={chartChecked(chart.value)}
                        type="checkbox"
                        className="w-4 h-4 border-gray-300 rounded focus:ring-primary text-primary"
                        onChange={() => {
                          handleOnChangeCharts(chart.value);
                        }}
                      />
                    </div>
                    <div className="flex justify-between w-full ml-3 text-sm">
                      <label
                        htmlFor={chart.value}
                        className="font-medium text-gray-700"
                      >
                        {chart.label}
                      </label>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
}
