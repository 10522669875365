import { YearsItems } from "@/lib/dto/management";
import { useGetDashboardLeasesQuery } from "@/lib/services/management";
import { leaseColor } from "@/pages/management";
import { t } from "@lingui/macro";
import { useEffect, useState } from "react";
import ChartCard from "../ChartCard";

interface Props {
  years: YearsItems[];
}

export default function DashboardLeases({ years }: Props) {
  const [currentYear, setCurrentYear] = useState<YearsItems>();
  const { data: dashboardLeases, isLoading: dashboardLeasesLoading } =
    useGetDashboardLeasesQuery(currentYear?.value, {
      skip: !currentYear?.value,
    });

  const tooltip = {
    theme: "dark",
    x: {
      show: false,
    },
    custom: function ({ series, seriesIndex, dataPointIndex, w }: any) {
      const ul = document.createElement("ul");
      ul.className = "custom-tooltip-apexchart";
      series.forEach((s: any, index: number) => {
        let li = document.createElement("li");
        li.innerHTML = ` <p> <span class="tooltip-color-legend" style="background: ${w.config.colors[index]}"></span> ${w.config.series[index].name}: ${s[dataPointIndex]}</p>`;
        ul.append(li);
      });

      return ul.outerHTML;
    },
  };

  useEffect(() => {
    if (years && !currentYear) setCurrentYear(years[0]);
  }, [years]);

  return (
    <ChartCard
      name="leases"
      title={t`Leases`}
      iconColor="text-safety-orange"
      colors={[leaseColor]}
      data={dashboardLeases}
      isLoading={dashboardLeasesLoading}
      years={years}
      currentYear={currentYear}
      setCurrentYear={setCurrentYear}
      tooltip={tooltip}
      note={t`New rental businesses`}
    />
  );
}
