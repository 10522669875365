import { YearsItems } from "@/lib/dto/management";
import {
  useGetDashboardLeadsQuery,
  useGetDashboardLeasesQuery,
} from "@/lib/services/management";
import { leaseColor, salesAndLeasesColors } from "@/pages/management";
import { t } from "@lingui/macro";
import { createElement, useEffect, useState } from "react";
import ChartCard from "../ChartCard";
interface Props {
  years: YearsItems[];
}

export default function DashboardLeads({ years }: Props) {
  const [currentYear, setCurrentYear] = useState<YearsItems>();
  const { data: dashboardLeads, isLoading: dashboardLeasesLoading } =
    useGetDashboardLeadsQuery(currentYear?.value, {
      skip: !currentYear?.value,
    });

  const tooltip = {
    theme: "dark",
    x: {
      show: false,
    },
    y: {
      formatter: (value: number, opts: any) => {
        return value;
      },
    },
  };

  useEffect(() => {
    if (years && !currentYear) setCurrentYear(years[0]);
  }, [years]);

  return (
    <ChartCard
      name="requirements"
      title={t`Requirements`}
      iconColor="text-safety-orange"
      colors={salesAndLeasesColors}
      data={dashboardLeads}
      isLoading={dashboardLeasesLoading}
      years={years}
      currentYear={currentYear}
      setCurrentYear={setCurrentYear}
      tooltip={tooltip}
    />
  );
}
