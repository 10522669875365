import Select from "react-select";
import StackedBars from "@/components/Management/StackedBars";
import { t, Trans } from "@lingui/macro";
import { classNames, OptionsForSelects, sumOfDataForCharts } from "@/lib/utils";
import { useEffect, useState } from "react";
import moment from "moment";
import MixedChart from "./MixedChart";
import StackedLoading from "./StackedLoading";
import NoDataChart from "./NoDataChart";
import { Stroke, Tooltip, Yaxis, YearsItems } from "@/lib/dto/management";
import { TooltipOptions } from "chart.js";

interface Props {
  data: any;
  colors: string[];
  icon?: string;
  iconColor?: string;
  legend?: [];
  title: string;
  chartType?: string;
  isLoading?: boolean;
  note?: string;
  years?: OptionsForSelects[];
  currentYear?: YearsItems;
  setCurrentYear: Function;
  stacked?: boolean;
  name: string;
  tooltip?: Tooltip;
  yaxis?: Yaxis;
  stroke?: Stroke;
}

export default function ChartCard({
  data,
  title,
  icon,
  chartType = "stacked",
  colors,
  iconColor,
  legend,
  isLoading,
  note,
  years,
  currentYear,
  setCurrentYear,
  stacked = false,
  name,
  tooltip,
  yaxis,
  stroke,
}: Props) {
  const [dataBuilder, setDataBuilder] = useState({
    series: [],
    xaxis: {
      xaxisBorder: {
        show: false,
      },
      categories: [],
    },
  });

  useEffect(() => {
    if (data) {
      setDataBuilder({
        series: data?.series,
        xaxis: {
          xaxisBorder: {
            show: false,
          },
          categories: data?.categories
            ? data?.categories?.map((item: string) =>
                moment(item) ? moment(item).format("MMM") : item
              )
            : [],
        },
      });
    }
  }, [data]);

  const thereIsNegativeValues = (data?: any): boolean => {
    if (!data) return false;
    return data.series.some((serie: any) =>
      serie.data.some((value: number) => value < 0)
    );
  };

  const isStacked = chartType === "stacked" && !thereIsNegativeValues(data);
  if (!isStacked && chartType === "stacked") {
    chartType = "bars";
  }

  return (
    <div className="bg-white rounded-md shadow p-2 h-full">
      <div className="flex justify-between items-top">
        <div className="flex gap-1 2xl:gap-2 items-center max-w-[135px]">
          <span
            className={classNames(
              iconColor ? iconColor : "",
              "material-icons-outlined"
            )}
          >
            {icon || "bar_chart"}
          </span>
          <div>
            <h3 className="text-md 2xl:text-lg font-semibold">{title}</h3>
            <p className="text-sm 2xl:text-md">
              12 <Trans>months</Trans>
            </p>
          </div>
        </div>

        {years?.length && (
          <div className="relative ">
            <Select
              placeholder={t`Select`}
              options={years}
              value={currentYear}
              onChange={(option) => setCurrentYear(option)}
              isDisabled={isLoading}
              className="react-select-with-icon"
            />
            <span className="material-icons-outlined text-dark-jungle absolute top-2 left-2 z-[60]">
              date_range
            </span>
          </div>
        )}
      </div>
      {isLoading ? (
        <>
          <StackedLoading name={name} />
        </>
      ) : (
        <>
          {sumOfDataForCharts(data) > 0 ? (
            <>
              {chartType === "stacked" || chartType === "bars" ? (
                <StackedBars
                  data={dataBuilder}
                  size={200}
                  colors={colors}
                  tooltip={tooltip}
                  stacked={isStacked}
                  yaxis={yaxis}
                />
              ) : (
                <MixedChart
                  data={dataBuilder}
                  size={200}
                  colors={colors}
                  tooltip={tooltip}
                  stroke={stroke}
                />
              )}

              {note && (
                <div className="px-4 text-gray-600">
                  <small>{note}</small>{" "}
                  <span className="text-fiery-rose">*</span>
                </div>
              )}
            </>
          ) : (
            <>
              <NoDataChart />
            </>
          )}
        </>
      )}
    </div>
  );
}
