import { t } from "@lingui/macro";
import { useEffect, useState } from "react";

interface Props {
  value: number;
}

export default function ImprovementBadge({ value }: Props) {
  const [data, setData] = useState({
    color: "",
    background: "",
    icon: "",
    message: "",
  });

  useEffect(() => {
    if (value > 0) {
      setData({
        color: "tiffany-blue",
        background: "bg-tiffany-blue/10",
        icon: "trending_up",
        message: `${value}% ${t`better than last month`}`,
      });
    } else if (value < 0) {
      setData({
        color: "fiery-rose",
        background: "bg-fiery-rose/10",
        icon: "trending_down",
        message: `${value}% ${t`worst than last month`}`,
      });
    } else {
      setData({
        color: "dark-jungle",
        background: "bg-dark-jungle/10",
        icon: "trending_flat",
        message: t`Equal than last month`,
      });
    }
  }, [value]);

  return (
    <>
      <div
        className={`rounded-full text-xs inline-flex items-center gap-2 px-3 ${data.background} text-${data.color}`}
      >
        <span className="material-icons-outlined">{data.icon}</span>
        {data.message}
      </div>
    </>
  );
}
