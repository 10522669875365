import { YearsItems } from "@/lib/dto/management";
import {
  useGetDashboardSalesMarginQuery,
  useGetDashboardSalesNetQuery,
  useGetDashboardTotalNetQuery,
} from "@/lib/services/management";
import { moneyFormatCurrency } from "@/lib/utils";
import {
  equipmentColor,
  leaseColor,
  marginColor,
  saleColor,
} from "@/pages/management";
import { t } from "@lingui/macro";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import ChartCard from "../ChartCard";

interface Props {
  years: YearsItems[];
}

export default function DashboardSalesMargin({ years }: Props) {
  const { locale } = useRouter();
  const [currentYear, setCurrentYear] = useState<YearsItems>();
  const { data: dashboardTotalNet, isLoading: dashboardTotalNetLoading } =
    useGetDashboardSalesMarginQuery(currentYear?.value, {
      skip: !currentYear?.value,
    });

  const tooltip = {
    theme: "dark",
    x: {
      show: false,
    },
    custom: function ({ series, seriesIndex, dataPointIndex, w }: any) {
      const ul = document.createElement("ul");
      ul.className = "custom-tooltip-apexchart";
      series.forEach((s: any, index: number) => {
        let value =
          index === 0
            ? moneyFormatCurrency(s[dataPointIndex], locale)
            : s[dataPointIndex];
        let li = document.createElement("li");
        li.innerHTML = ` <p class="flex justify-between items-center gap-2"> <span><span class="tooltip-color-legend" style="background: ${w.config.colors[index]}"></span> ${w.config.series[index].name}:</span> ${value}</p>`;
        ul.append(li);
      });

      return ul.outerHTML;
    },
  };

  useEffect(() => {
    if (years && !currentYear) setCurrentYear(years[0]);
  }, [years]);

  return (
    <ChartCard
      name="margin_sales_net"
      chartType="mixed"
      title={t`Sales margin ($)`}
      iconColor="text-blue-crayola"
      icon="auto_graph"
      colors={[saleColor, marginColor]}
      data={dashboardTotalNet}
      isLoading={dashboardTotalNetLoading}
      years={years}
      currentYear={currentYear}
      setCurrentYear={setCurrentYear}
      tooltip={tooltip}
    />
  );
}
