import { moneyFormatCurrency } from "@/lib/utils";
import { t } from "@lingui/macro";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";

const Chart = dynamic(() => import("react-apexcharts"), { ssr: false });

interface Props {
  data: any;
  locale?: string;
  currency?: string;
}

export default function DonutChart({ data, locale, currency }: Props) {
  const { locale: lcl } = useRouter();
  const options: any = {
    options: {
      chart: {
        type: "donut",
      },
      colors: ["#17C3B2", "#FFF"],
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        theme: false,
        y: {
          formatter: (value: number, opts: any) => {
            return moneyFormatCurrency(value, locale ?? lcl, currency);
          },
        },
      },
      labels: [t`Invoiced`, t`Pending`],
    },
  };

  return (
    <div className="p-2">
      {data && (
        <div style={{ height: 150 }}>
          <Chart
            options={options.options}
            series={data.series}
            type="donut"
            height={150}
          />
        </div>
      )}
    </div>
  );
}
