import { YearsItems } from "@/lib/dto/management";
import { useGetDashboardLeaseDaysQuery } from "@/lib/services/management";
import {
  averageDaysColor,
  leaseColor,
  marginColor,
  newLeasesColor,
  saleColor,
} from "@/pages/management";
import { t } from "@lingui/macro";
import { useEffect, useState } from "react";
import ChartCard from "../ChartCard";

interface Props {
  years: YearsItems[];
}

export default function DashboardLeaseDays({ years }: Props) {
  const [currentYear, setCurrentYear] = useState<YearsItems>();
  const { data: dashboardLeaseDays, isLoading: dashboardLeaseDaysLoading } =
    useGetDashboardLeaseDaysQuery(currentYear?.value, {
      skip: !currentYear?.value,
    });

  const tooltip = {
    theme: "dark",
    x: {
      show: false,
    },
    y: {
      formatter: (value: number, opts: any) => {
        return value;
      },
    },
  };

  const stroke = {
    width: [0, 4, 4],
  };

  useEffect(() => {
    if (years && !currentYear) setCurrentYear(years[0]);
  }, [years]);

  return (
    <ChartCard
      name="lease_days"
      title={t`Lease days`}
      icon="calendar_today"
      iconColor="text-safety-orange"
      colors={[leaseColor, averageDaysColor, newLeasesColor]}
      data={dashboardLeaseDays}
      isLoading={dashboardLeaseDaysLoading}
      years={years}
      chartType="mixed"
      currentYear={currentYear}
      setCurrentYear={setCurrentYear}
      tooltip={tooltip}
      stroke={stroke}
    />
  );
}
