import { Stroke, Tooltip } from "@/lib/dto/management";
import { ChartType, TooltipOptions } from "chart.js";
import dynamic from "next/dynamic";
import { useEffect, useState } from "react";

const Chart = dynamic(() => import("react-apexcharts"), { ssr: false });

interface Props {
  data: any;
  size?: number;
  colors: string[];
  tooltip?: Tooltip;
  stroke?: Stroke;
}

export default function MixedChart({
  data,
  size = 150,
  colors,
  tooltip,
  stroke,
}: Props) {
  const [options, setOptions] = useState({
    series: data.series,
    options: {
      chart: {
        height: size,
        type: "line" as ChartType,
        toolbar: {
          show: true,
        },
      },
      stroke: {
        width: [0, 4],
      },
      legend: {
        position: "bottom",
        horizontalAlign: "left",
        markers: {
          radius: 6,
          offsetY: 2,
          offsetX: -2,
        },
        labels: {
          useSeriesColors: true,
        },
        fontFamily: "Nunito Sans",
        fontSize: "12px",
      },
      dataLabels: {
        enabled: false,
      },
      grid: {
        show: false,
      },
      colors: colors,
      xaxis: {},
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: data[0]?.data.length > 12 ? 8 : 14,
          borderRadiusApplication: "around", // 'around', 'end'
          borderRadiusWhenStacked: "all", // 'all', 'last'
          dataLabels: {
            total: {
              enabled: true,
              style: {
                fontSize: "13px",
                fontWeight: 900,
              },
            },
          },
        },
      },
      yaxis: [
        {
          show: false,
        },
        {
          show: false,
        },
        {
          show: false,
        },
      ],
      tooltip: {
        shared: false,
        intersect: true,
        custom: function ({ series, seriesIndex, dataPointIndex, w }: any) {
          let currentTotal = 0;
          const ul = document.createElement("ul");
          ul.className = "custom-tooltip-apexchart";
          series.forEach((s: any, index: number) => {
            currentTotal += s[dataPointIndex];
            let li = document.createElement("li");
            li.innerHTML = ` <p class="flex justify-between items-center gap-2"> <span><span class="tooltip-color-legend" style="background: ${w.config.colors[index]}"></span> ${w.config.series[index].name}:</span> ${s[dataPointIndex]}</p>`;
            ul.append(li);
          });

          return ul.outerHTML;
        },
      },
    },
  });

  useEffect(() => {
    if (data) {
      setOptions({
        ...options,
        series: data.series,
        options: {
          ...options.options,
          stroke: {
            ...options.options.stroke,
            ...stroke,
          },
          plotOptions: {
            ...options.options.plotOptions,
            bar: {
              ...options.options.plotOptions.bar,
              borderRadius:
                data[0]?.data.length > 12 || data.series.length > 2 ? 8 : 14,
            },
          },
          xaxis: data.xaxis,
          tooltip: {
            ...options.options.tooltip,
            ...tooltip,
          },
        },
      });
    }
  }, [data]);

  return (
    <div className="">
      {data && (
        <div style={{ height: size }}>
          <Chart
            // @ts-ignore
            options={options.options}
            series={options.series}
            type="line"
            height={size}
          />
        </div>
      )}
    </div>
  );
}
